import { h, render } from "preact";
import { t, translate } from "../I18n";
import { I18n } from "../services/I18n";
import AvailabilityService from "../services/AvailabilityService.js";
import DateTimeService from "../services/DateTimeService";
import Cmd from "../utils/Cmd.js";
import loop from "../utils/loop.js";
import UpcomingHolidays from "./UpcomingHolidays";
const { isInRange } = AvailabilityService;
const { assign } = Object;
const localizePath = (path) => {
    if (I18n.locale == I18n.defaultLocale) {
        return `/${path}`;
    }
    else {
        return `/${I18n.locale}/${path}`;
    }
};
const apiPath = (slug) => "/api/v1" + localizePath(`restaurants/${slug}/hours`);
const emptyRestaurant = (slug) => ({
    slug,
    holidays: [],
    isHoursModuleLoading: false,
    isHoursModuleLoaded: false,
    onlineHours: [],
    openingHours: [],
    phoneHours: [],
    upcomingHolidays: [],
});
const emptyAvailability = () => ({
    isEnabled: false,
    isOnlinePossible: false,
    isHoursModuleLoading: false,
    isHoursModuleLoaded: false,
    onlineHoursType: "",
    onlineHoursString: "",
    info: undefined,
});
const init = (state = {}) => {
    initCollapseListeners();
    return [
        assign({
            slug: "",
            isEnabled: false,
            restaurants: [],
            restaurantsAvailability: [],
            ordering: emptyAvailability(),
        }, state),
        Cmd.of((dispatch) => {
            loop(() => dispatch({ type: "UPDATE_HOURS" }), 60);
        }),
    ];
};
const update = (state, action) => {
    switch (action.type) {
        case "GET_RESTAURANT_HOURS":
            return [
                assign({}, state),
                Cmd.of((dispatch) => {
                    var _a, _b;
                    // Save data from menu response while loaded
                    document.addEventListener("loaded.restaumatic.cart", (e) => {
                        const restaurant = Skubacz.CartHooks.restaurant();
                        dispatch({
                            type: "STORE_RESTAURANT_DATA",
                            payload: {
                                slug: restaurant.slug,
                                isEnabled: restaurant.enabled && restaurant.online_ordering_enabled,
                            },
                        });
                        dispatch({ type: "UPDATE_HOURS" });
                    });
                    // Fetch hours for specific restaurant slug
                    const slug = action.payload.slug;
                    const loading = (_b = (_a = state.restaurants.find((r) => r.slug == slug)) === null || _a === void 0 ? void 0 : _a.isHoursModuleLoading) !== null && _b !== void 0 ? _b : false;
                    if (loading) {
                        return;
                    }
                    dispatch({ type: "LOADING_HOURS", payload: { slug } });
                    fetch(apiPath(slug))
                        .then((r) => r.json())
                        .then((result) => {
                        const payload = {
                            slug,
                            openingHours: result.restaurant_hours,
                            onlineHours: result.online_hours,
                            phoneHours: result.phone_hours,
                            holidays: result.holidays,
                            upcomingHolidays: result.upcoming_holidays,
                            isHoursModuleLoading: false,
                            isHoursModuleLoaded: true,
                        };
                        dispatch({ type: "STORE_HOURS", payload });
                        dispatch({ type: "UPDATE_HOURS" });
                    });
                }),
            ];
        case "LOADING_HOURS":
            const { slug } = action.payload;
            const restaurant = (state.restaurants || []).find((r) => r.slug === slug) ||
                emptyRestaurant(slug);
            return [
                assign({}, state),
                Cmd.of((dispatch) => dispatch({
                    type: "STORE_HOURS",
                    payload: { ...restaurant, isHoursModuleLoading: true },
                })),
            ];
        case "STORE_RESTAURANT_DATA":
            return [
                assign({}, state, {
                    slug: action.payload.slug,
                    isEnabled: action.payload.isEnabled,
                }),
                Cmd.none,
            ];
        case "STORE_HOURS":
            const restaurants = (state.restaurants || []).filter((r) => r.slug != action.payload.slug);
            restaurants.push(action.payload);
            return [assign({}, state, { restaurants }), Cmd.none];
        case "UPDATE_HOURS":
            const dateTime = DateTimeService.getDateTime(Skubacz.configuration.time_zone);
            const dateTimeString = DateTimeService.timeToString(dateTime, "YYYY-MM-DD HH:mm:ss");
            const restaurantsAvailability = (state.restaurants || []).map((r) => assign({
                slug: r.slug,
                isHoursModuleLoading: r.isHoursModuleLoading,
                isHoursModuleLoaded: r.isHoursModuleLoaded,
                upcomingHolidays: r.upcomingHolidays || [], // || [] added here because of caching errors?
            }, getHolidaysData(r.holidays, dateTimeString), getOpeningHoursData(r.openingHours, r.holidays, dateTimeString), getOnlineHoursData(r.onlineHours, r.holidays, dateTimeString), getPhoneHoursData(r.phoneHours, r.holidays, dateTimeString)));
            const av = restaurantsAvailability.find((x) => x.slug == state.slug) ||
                emptyAvailability();
            const ordering = {
                isEnabled: state.isEnabled || false,
                isOnlinePossible: av.isOnlinePossible,
                onlineHoursType: av.onlineHoursType,
                onlineHoursString: av.onlineHoursString,
                isHoursModuleLoading: av.isHoursModuleLoading,
                isHoursModuleLoaded: av.isHoursModuleLoaded,
                info: av.info,
            };
            return [
                assign({}, state, { restaurantsAvailability, ordering }),
                Cmd.none,
            ];
        default:
            return [state, Cmd.none];
    }
};
/**
 * Show/hide present day text and buttons when collapsible is shown/hidden
 */
const initCollapseListeners = () => {
    const body = document.querySelector("body");
    if (!body) {
        return;
    }
    const getPresentDayElementsOf = (element) => {
        var _a, _b;
        try {
            return (_b = (_a = element === null || element === void 0 ? void 0 : element.closest(".js-restaurant-hours")) === null || _a === void 0 ? void 0 : _a.querySelector(".js-restaurant-hours-present-day")) === null || _b === void 0 ? void 0 : _b.querySelectorAll(".js-restaurant-hours-header, .js-restaurant-hours-data, .js-restaurant-hours-btn-popover, .js-restaurant-hours-now");
        }
        catch (error) {
            console.error(`Restaurant Hours: no event target found for collapsible elements`, error);
        }
    };
    const onCollapseShow = (event) => {
        var _a;
        (_a = getPresentDayElementsOf(event.target)) === null || _a === void 0 ? void 0 : _a.forEach((el) => el.classList.add("u-visibility-hidden"));
    };
    const onCollapseHide = (event) => {
        var _a;
        (_a = getPresentDayElementsOf(event.target)) === null || _a === void 0 ? void 0 : _a.forEach((el) => el.classList.remove("u-visibility-hidden"));
    };
    body.addEventListener("show.bs.collapse", onCollapseShow);
    body.addEventListener("hide.bs.collapse", onCollapseHide);
};
const prepairDateTimeData = (dateTimeString) => {
    const dateTime = DateTimeService.parseDateTime(dateTimeString, "YYYY-MM-DD HH:mm:ss");
    const timeString = DateTimeService.timeToString(dateTime, "HH:mm:ss");
    const timeInSeconds = DateTimeService.timeToSeconds(timeString);
    const oneDayInSeconds = DateTimeService.daysToSeconds(1);
    const yesterdayDateTime = DateTimeService.shiftDays(dateTime, -1);
    const todayDayIndex = DateTimeService.dayIndex(dateTime);
    const todayDayIndexIso = DateTimeService.dayIndexIso(dateTime);
    const yesterdayDayIndex = DateTimeService.dayIndex(yesterdayDateTime);
    const yesterdayDayIndexIso = DateTimeService.dayIndexIso(yesterdayDateTime);
    return {
        dateTime,
        yesterdayDateTime,
        oneDayInSeconds,
        timeInSeconds,
        todayDayIndex,
        todayDayIndexIso,
        yesterdayDayIndex,
        yesterdayDayIndexIso,
    };
};
const filterHolidays = (holidays, dateTimeString) => {
    const { dateTime, yesterdayDateTime } = prepairDateTimeData(dateTimeString);
    const todayHolidays = holidays.find((h) => DateTimeService.isDayBetween(dateTime, DateTimeService.parseDateTime(h.start_date, "YYYY-MM-DD"), DateTimeService.parseDateTime(h.end_date, "YYYY-MM-DD")));
    const yesterdayHolidays = holidays.find((h) => DateTimeService.isDayBetween(yesterdayDateTime, DateTimeService.parseDateTime(h.start_date, "YYYY-MM-DD"), DateTimeService.parseDateTime(h.end_date, "YYYY-MM-DD")));
    return {
        todayHolidays,
        yesterdayHolidays,
        hasTodayHolidays: todayHolidays !== undefined,
        hasYesterdayHolidays: yesterdayHolidays !== undefined,
    };
};
const getHolidaysData = (holidays, dateTimeString) => {
    const { todayHolidays, hasTodayHolidays, yesterdayHolidays, hasYesterdayHolidays, } = filterHolidays(holidays, dateTimeString);
    return {
        holidaysHoursString: hasTodayHolidays
            ? todayHolidays.hours
            : hasYesterdayHolidays
                ? yesterdayHolidays.hours
                : "",
        holidaysInfo: hasTodayHolidays
            ? todayHolidays.info
            : hasYesterdayHolidays
                ? yesterdayHolidays.info
                : "",
    };
};
const getHoursData = (hours, holidays, dateTimeString) => {
    var _a, _b, _c, _d, _e;
    const { oneDayInSeconds, timeInSeconds, todayDayIndex, todayDayIndexIso, yesterdayDayIndex, yesterdayDayIndexIso, } = prepairDateTimeData(dateTimeString);
    const { todayHolidays, hasTodayHolidays, yesterdayHolidays, hasYesterdayHolidays, } = filterHolidays(holidays, dateTimeString);
    const todayHours = hasTodayHolidays
        ? todayHolidays
        : hours[todayDayIndex];
    const isActiveToday = isInRange((_a = todayHours === null || todayHours === void 0 ? void 0 : todayHours.time_range) === null || _a === void 0 ? void 0 : _a.start, (_b = todayHours === null || todayHours === void 0 ? void 0 : todayHours.time_range) === null || _b === void 0 ? void 0 : _b.stop, timeInSeconds);
    const yesterdayHours = hasYesterdayHolidays
        ? yesterdayHolidays
        : hours[yesterdayDayIndex];
    const isActiveYesterday = isInRange(0, (_c = yesterdayHours === null || yesterdayHours === void 0 ? void 0 : yesterdayHours.time_range) === null || _c === void 0 ? void 0 : _c.stop, timeInSeconds + oneDayInSeconds);
    const currHours = isActiveToday || !isActiveYesterday ? todayHours : yesterdayHours;
    return {
        isActive: isActiveToday || isActiveYesterday,
        dayIndex: isActiveToday || !isActiveYesterday
            ? todayDayIndexIso
            : yesterdayDayIndexIso,
        hoursType: (_d = currHours === null || currHours === void 0 ? void 0 : currHours.type) !== null && _d !== void 0 ? _d : "",
        hoursString: (_e = currHours === null || currHours === void 0 ? void 0 : currHours.hours) !== null && _e !== void 0 ? _e : "",
        info: currHours && "info" in currHours
            ? currHours.info
            : undefined,
    };
};
const getOpeningHoursData = (hours, holidays, dateTimeString) => {
    const { isActive, dayIndex, hoursType, hoursString } = getHoursData(hours, holidays, dateTimeString);
    return {
        isRestaurantOpen: isActive,
        openingDayIndex: dayIndex,
        openingHoursType: hoursType,
        openingHoursString: hoursString,
    };
};
const getOnlineHoursData = (hours, holidays, dateTimeString) => {
    const { isActive, dayIndex, hoursType, hoursString, info } = getHoursData(hours, holidays, dateTimeString);
    return {
        isOnlinePossible: isActive,
        onlineDayIndex: dayIndex,
        onlineHoursType: hoursType,
        onlineHoursString: hoursString,
        info: info,
    };
};
const getPhoneHoursData = (hours, holidays, dateTimeString) => {
    const { isActive, dayIndex, hoursType, hoursString } = getHoursData(hours, holidays, dateTimeString);
    return {
        isPhonePossible: isActive,
        phoneDayIndex: dayIndex,
        phoneHoursType: hoursType,
        phoneHoursString: hoursString,
    };
};
/**
 * This takes restaurant hours element and related to it state and
 * - fetches data if not fetched yet
 * - updates the element with data
 * - hide/show elements based on data
 *
 * NOTICE: This code is rewritten (and partially AI generated) from the Purescript RestaurantHoursPS.purs
 */
const view = (element, state, dispatch) => {
    const slug = element.getAttribute("data-restaurant-slug");
    if (!slug) {
        return;
    }
    onInit(element, () => executeWhenDomLoaded(dispatch({ type: "GET_RESTAURANT_HOURS", payload: { slug } })));
    const availability = state.restaurantsAvailability.find((x) => x.slug === slug);
    if (availability) {
        const openingHoursWrapper = findByClassIncludeSelf("js-restaurant-hours-opening", element)[0];
        const onlineHoursWrapper = findByClassIncludeSelf("js-restaurant-hours-online", element)[0];
        if (openingHoursWrapper) {
            openingHoursView(openingHoursWrapper, availability);
        }
        if (onlineHoursWrapper) {
            onlineHoursView(onlineHoursWrapper, availability);
        }
        upcomingHolidaysWidget(element, availability.upcomingHolidays);
    }
    // Hide holidays widget wrapper or render its content if holidays present
    function upcomingHolidaysWidget(elem, upcomingHolidays) {
        const contentEl = elem.querySelector(".js-upcoming-holidays-content");
        if (contentEl) {
            const wrapperEl = contentEl.closest(".js-upcoming-holidays-wrapper");
            if (wrapperEl) {
                const config = getHolidayConfig(wrapperEl);
                if (upcomingHolidays.length === 0) {
                    hideElem(wrapperEl);
                }
                else {
                    showElem(wrapperEl);
                    render(h(UpcomingHolidays, { className: config.modifierClasses, upcomingHolidays: upcomingHolidays }), contentEl);
                }
            }
        }
    }
    function onInit(element, initializer) {
        try {
            const initialized = element.getAttribute("data-initialized");
            if (initialized !== "true") {
                element.setAttribute("data-initialized", "true");
                initializer();
            }
        }
        catch (error) {
            console.error("Error during RestaurantHours initialization", error);
        }
    }
    function executeWhenDomLoaded(listenerFunction) {
        const doc = window.document;
        const state = doc.readyState;
        const listener = () => listenerFunction();
        if (state === "loading") {
            doc.addEventListener("DOMContentLoaded", listener);
        }
        else {
            setTimeout(listenerFunction, 0);
        }
    }
    function openingHoursView(elem, av) {
        hoursViewNow(elem, av.isRestaurantOpen);
        hoursViewPresentDay(elem, av.isRestaurantOpen, av.openingHoursString);
        hoursViewAllDays(elem);
        hoursViewToday(elem, av.isRestaurantOpen, av.openingDayIndex);
        const popover = elem.querySelector(".js-restaurant-hours-popover");
        const title = av.isRestaurantOpen
            ? translate(t.restaurants.hours.open)
            : translate(t.restaurants.hours.closed);
        const desc = av.holidaysHoursString === ""
            ? ""
            : `${translate(t.restaurants.hours.holidays)}: ${av.holidaysHoursString}`;
        const info = av.holidaysInfo;
        if (popover) {
            popoverView(popover, av.isRestaurantOpen, title, desc, info);
        }
    }
    function onlineHoursView(elem, av) {
        hoursViewNow(elem, av.isOnlinePossible);
        hoursViewPresentDay(elem, av.isOnlinePossible, av.onlineHoursString);
        hoursViewAllDays(elem);
        hoursViewToday(elem, av.isOnlinePossible, av.onlineDayIndex);
        const popover = elem.querySelector(".js-restaurant-hours-popover");
        const title = av.isOnlinePossible
            ? translate(t.restaurants.hours.active)
            : translate(t.restaurants.hours.inactive);
        const desc = av.holidaysHoursString === ""
            ? ""
            : `${translate(t.restaurants.hours.holidays)}: ${av.holidaysHoursString}`;
        const info = av.holidaysInfo;
        if (popover) {
            popoverView(popover, av.isOnlinePossible, title, desc, info);
        }
    }
    function getHolidayConfig(elem) {
        const holidayConfig = elem.getAttribute("data-widget-config");
        if (!holidayConfig) {
            const errMsg = "Element doesn't have the data-widget-config attribute set.";
            console.error(errMsg, elem);
            return { modifierClasses: "" };
        }
        try {
            const res = JSON.parse(holidayConfig);
            return res;
        }
        catch (error) {
            const errMsg = `Element doesn't have the property data-widget-config set correctly, current value: ${holidayConfig}. Error: ${error instanceof Error ? error.message : String(error)}`;
            console.error(errMsg, elem);
            return { modifierClasses: "" };
        }
    }
    function findByClassIncludeSelf(className, elem) {
        const children = Array.from(elem.querySelectorAll(`.${className}`));
        const shouldIncludeSelf = elem.classList.contains(className);
        return shouldIncludeSelf ? [elem, ...children] : children;
    }
    // Shows proper status icon or text (active / not active)
    // NOTICE: not only in RestaurantHours widget but also in Header as a standalone "Open now" / "Closed now" info
    function hoursViewNow(elem, makeActive) {
        const isActiveEl = elem.querySelector(".js-restaurant-hours-is-active");
        const isInactiveEl = elem.querySelector(".js-restaurant-hours-is-inactive");
        if (isActiveEl && isInactiveEl) {
            if (makeActive) {
                showElem(isActiveEl);
                hideElem(isInactiveEl);
            }
            else {
                hideElem(isActiveEl);
                showElem(isInactiveEl);
            }
        }
    }
    // Sets data for "today row" (collapsed view)
    // Changes classes for "today row" to active/inactive
    function hoursViewPresentDay(elem, makeActive, dayHours) {
        var _a;
        const activeClasses = getDataString(elem, "active-classes"); // e.g., "text-success"
        const inactiveClasses = getDataString(elem, "inactive-classes"); // e.g., "text-danger"
        const presentDayWrapper = (_a = findByClassIncludeSelf("js-restaurant-hours-present-day", elem)) === null || _a === void 0 ? void 0 : _a[0];
        if (presentDayWrapper) {
            const presentDayData = presentDayWrapper.querySelector(".js-restaurant-hours-data");
            if (presentDayData) {
                presentDayData.innerHTML = dayHours;
            }
            if (makeActive) {
                swapClasses(presentDayWrapper, activeClasses, inactiveClasses);
            }
            else {
                swapClasses(presentDayWrapper, inactiveClasses, activeClasses);
            }
        }
    }
    function getDataString(elem, dataAttribute) {
        return elem.getAttribute(`data-${dataAttribute}`) || "";
    }
    function swapClasses(elem, addClassName, removeClassName) {
        addClass(elem, addClassName);
        removeClass(elem, removeClassName);
    }
    function showElem(elem) {
        removeClass(elem, "hide");
    }
    function hideElem(elem) {
        addClass(elem, "hide");
    }
    function addClass(elem, className) {
        className.length > 0 ? elem.classList.add(className) : undefined;
    }
    function removeClass(elem, className) {
        className.length > 0 ? elem.classList.remove(className) : undefined;
    }
    // Hides all days' icons & popover triggers
    function hoursViewAllDays(elem) {
        var _a;
        const allDaysWrapper = (_a = findByClassIncludeSelf("js-restaurant-hours-all-days", elem)) === null || _a === void 0 ? void 0 : _a[0];
        if (allDaysWrapper) {
            const days = allDaysWrapper.querySelectorAll(".js-restaurant-hours-day");
            days.forEach((day) => {
                const otherDaysEls = day.querySelectorAll(".js-restaurant-hours-btn-popover, .js-restaurant-hours-now");
                otherDaysEls.forEach((el) => hideElem(el));
            });
        }
    }
    // Shows icons & popover triggers for today
    // Changes classes for today to active/inactive
    // Shows proper icon (open / closed) for today
    function hoursViewToday(elem, makeActive, dayIndex) {
        var _a;
        const activeClasses = getDataString(elem, "active-classes");
        const inactiveClasses = getDataString(elem, "inactive-classes");
        const allDaysWrapper = (_a = findByClassIncludeSelf("js-restaurant-hours-all-days", elem)) === null || _a === void 0 ? void 0 : _a[0];
        if (allDaysWrapper) {
            const days = allDaysWrapper.querySelectorAll(".js-restaurant-hours-day");
            const todayElem = filterElements(days, (x) => {
                const day = x.getAttribute("data-day");
                if (day === null)
                    return false;
                const parsedDay = parseInt(day, 10);
                return !isNaN(parsedDay) && parsedDay === dayIndex;
            });
            if (todayElem.length > 0) {
                const today = todayElem[0];
                const todayElems = today.querySelectorAll(".js-restaurant-hours-btn-popover, .js-restaurant-hours-now");
                todayElems.forEach((el) => {
                    showElem(el);
                });
                if (makeActive) {
                    swapClasses(today, activeClasses, inactiveClasses);
                }
                else {
                    swapClasses(today, inactiveClasses, activeClasses);
                }
                hoursViewNow(today, makeActive);
            }
        }
    }
    function filterElements(arr, predicate) {
        const results = Array.from(arr).map(predicate);
        return Array.from(arr).filter((_, index) => results[index]);
    }
    // Sets title, desc & info data for popovers
    // Changes classes for texts to active/inactive
    function popoverView(popover, makeActive, title, desc, info) {
        const popoverTitle = popover.querySelector(".js-restaurant-hours-popover-title");
        if (popoverTitle) {
            popoverTitle.innerHTML = title;
        }
        const popoverDetails = popover.querySelector(".js-restaurant-hours-popover-details");
        if (popoverDetails) {
            popoverDetails.innerHTML = desc;
        }
        const popoverInfo = popover.querySelector(".js-restaurant-hours-popover-info");
        if (popoverInfo) {
            popoverInfo.innerHTML = info;
        }
        [popoverTitle, popoverDetails, popoverInfo]
            .filter(Boolean)
            .forEach((element) => {
            if (element) {
                if (makeActive) {
                    swapClasses(element, "text-success", "text-danger");
                }
                else {
                    swapClasses(element, "text-danger", "text-success");
                }
            }
        });
    }
};
export default {
    init,
    update,
    prepairDateTimeData,
    filterHolidays,
    getHolidaysData,
    getOpeningHoursData,
    getOnlineHoursData,
    getPhoneHoursData,
    view,
};
