import { DaySecond, FULL_DAY } from "restaumatic-client/src";
import { t, translate } from "../I18n";
import { groupBy } from "./groupBy";
// Weekdays in order of display, with their index in OpeningHours data structure (which is indexed in a different order :( )
const WEEKDAYS = [
    { name: "monday", openingHoursIndex: 1 },
    { name: "tuesday", openingHoursIndex: 2 },
    { name: "wednesday", openingHoursIndex: 3 },
    { name: "thursday", openingHoursIndex: 4 },
    { name: "friday", openingHoursIndex: 5 },
    { name: "saturday", openingHoursIndex: 6 },
    { name: "sunday", openingHoursIndex: 0 },
];
export function formatOpeningHours(hours) {
    return groupBy((a, b) => a.h.start === b.h.start && a.h.stop === b.h.stop, WEEKDAYS.map((w) => ({
        name: translate(t.date.day.abbr_names[w.name]) + ".",
        h: hours.days[w.openingHoursIndex],
    })))
        .map((group) => {
        const name = group.length > 1
            ? `${group[0].name}-${group[group.length - 1].name}`
            : group[0].name;
        const h = group[0].h;
        const from = DaySecond.fromNumber(h.start);
        const to = DaySecond.fromNumber(h.stop % FULL_DAY);
        return `${name}: ${from.toString()} - ${to.toString()}`;
    })
        .join("\n");
}
