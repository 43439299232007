import { h } from "preact";
import { observer } from "mobx-react";
import FieldError from "./FieldError";
import FieldHint from "./FieldHint";
import FieldLabel from "./FieldLabel";
function TextField(props) {
    const { controller, name, label, type = "text", autocomplete, placeholder, hint, required = false, disabled = false, autofocus = false, dataFieldId, onBlur, onChange, } = props;
    const handleChange = (event) => {
        const value = event.target.value;
        controller.change(value);
        // TODO: (B2C-256) remove it
        if (onChange !== undefined) {
            onChange();
        }
    };
    const handleBlur = () => {
        controller.blur();
        // TODO: (B2C-256) remove it
        if (onBlur !== undefined) {
            onBlur();
        }
    };
    const hasError = controller.isTouched && controller.isInvalid;
    const hasHint = hint && hint.length > 0;
    const isSuccess = controller.isTouched && controller.isValid;
    const descriptionId = `${name}-desc`;
    return (
    // TODO: wchich is proper class? form-field or m-form__group? (are used alternately)
    // TODO: export <FormField> component for TextField, PhoneField (and more...?)
    h("div", { class: `form-group m_form-group form-field ${hasError ? "has-error" : ""} ${isSuccess ? "success" : ""} ${disabled ? "is-disabled" : ""}`, "data-field-id": dataFieldId },
        h(FieldLabel, { isRequired: required, htmlFor: name }, label),
        h("input", { id: name, name: name, onChange: handleChange, onBlur: handleBlur, value: controller.value, type: type, autofocus: autofocus, autocomplete: autocomplete, placeholder: placeholder, required: required, disabled: disabled, class: "form-control u-text-normal", "aria-describedby": hasHint ? descriptionId : "" }),
        hasError && h(FieldError, { errors: controller.errors }),
        hasHint && h(FieldHint, { id: descriptionId, message: hint })));
}
export default observer(TextField);
