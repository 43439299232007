/** @jsx h */
import { h, Fragment } from "preact";
import { useContext } from "preact/hooks";
import { renderPreact } from "../lib/render-preact";
import PageContext from "./PageContext";
import { translate, t } from "../I18n";
function PoweredBy(props) {
    const settings = useContext(PageContext);
    return (h(Fragment, null,
        h("span", null, translate(t.theme_defaults.theme.powered_by)),
        h("a", { href: "//www.restaumatic.com/" + settings.locale, class: props.poweredByLinkClass || "u-link-wrapper", target: "_parent", "data-ga-action": "Clicks on the Restaumatic link in the footer", rel: "nofollow" },
            "\u00A0",
            h("svg", { width: "130", height: "26", class: "svg-icon-restaumatic u-va-middle" },
                h("use", { xlinkHref: "#svg-icon-restaumatic" })))));
}
export default renderPreact("PoweredBy", PoweredBy);
