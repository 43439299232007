import App from "./components/App";
import Store from "./services/Store";
import testLocalStorage from "./utils/testLocalStorage";
import initCartFabAttention from "./utils/initCartFabAttention";
import { init as initRating } from "./utils/Rating";
import CookieConsent from "./utils/CookieConsent";
import * as GroupToggle from "./components/GroupToggle";

// SSR components
import * as SSR from "./ssr";

const client = {
  /**
   * Init App component with global store.
   */
  initApp: function () {
    const initialState = {};
    const appStore = Store.create(App.init(initialState), App.update);

    appStore.subscribe(() => {
      App.view(appStore.getState(), appStore.dispatch);
    });

    appStore.dispatch({ type: "INIT" });
  },

  /**
   * Execute immediately without waiting for DOM.
   */
  executeImmediately: function () {
    GroupToggle.utils.updateIdAttr();

    // replace with empty function, so we don't log errors after unload
    window.onbeforeunload = function () {
      window.onerror = function (message, url, line_number) {};
    };
  },

  initMenuPage: function () {
    if (document.querySelector("[data-cart]") !== null) {
      if (isNewClientEnabled()) {
        import(
          /* webpackChunkName: "MenuPageNewCart" */ "./views/MenuPageNewCart"
        ).then((mod) => mod["default"].init());
      } else {
        import(/* webpackChunkName: "MenuPage" */ "./views/MenuPage").then(
          (mod) => mod["default"].init()
        );
      }
    }
  },

  initCheckout: function () {
    if (document.querySelector(".ps-checkout")) {
      if (isNewClientEnabled()) {
        import(
          /* webpackChunkName: "NewCheckout" */ "./views/Checkout/init"
        ).then((checkoutPage) =>
          checkoutPage.init(document.querySelector(".ps-checkout"))
        );
      } else {
        import(
          /* webpackChunkName: "Checkout" */ "./components/CheckoutWrapper"
        ).then((mod) => mod["default"].init());
      }
    }
  },

  initOrderInfo: function () {
    if (document.querySelector(".ps-order-info")) {
      if (isNewClientEnabled()) {
        import(
          /* webpackChunkName: "NewThankYouPage" */ "./views/ThankYouPage/init"
        ).then((thankYouPage) =>
          thankYouPage.init(document.querySelector(".ps-order-info"))
        );
      } else {
        import(
          /* webpackChunkName: "OrderInfo" */ "./components/OrderInfoWrapper"
        ).then((mod) => mod["default"].init());
      }
    }
  },

  initReservation: function () {
    if (document.getElementById("ps-reservation")) {
      import(
        /* webpackChunkName: "Reservation" */ "./components/ReservationWrapper"
      ).then((mod) => mod["default"].init());
    }
  },

  initConsents: function () {
    if (document.getElementById("ps-consents")) {
      import(
        /* webpackChunkName: "Consents" */ "./components/ConsentsWrapper"
      ).then((mod) => mod["default"].init());
    }
  },

  /**
   * Init cookie warning
   */
  initCookieConsent: function () {
    CookieConsent.init();
  },

  initSelectRestaurant: () => {
    const selectRestaurant = document.querySelector(".js-select-restaurant");
    if (selectRestaurant) {
      import(
        /* webpackChunkName: "SelectRestaurant" */ "../../output/Components.SelectRestaurant/index.js"
      ).then((mod) =>
        mod.init({
          element: selectRestaurant,
          country: Skubacz.configuration.country,
          accountId: Skubacz.configuration.account_id,
        })
      );
    }
  },

  initDeliveryForm: () => {
    const deliveryModule = document.querySelector(".js-delivery-module");
    if (deliveryModule) {
      import(
        /* webpackChunkName: "DeliveryForm" */ "../../output/Components.DeliveryForm/index.js"
      ).then((mod) =>
        mod.init({
          element: deliveryModule,
          country: Skubacz.configuration.country,
          accountId: Skubacz.configuration.account_id,
        })
      );
    }
  },
};

/**
 * Should we use the new client implementation?
 * New client is used whenever ANY of the old flags was enabled
 */
export function isNewClientEnabled() {
  for (const urlFlag of [
    "__NewProductCard__",
    "__NewCart__",
    "__NewCheckout__",
    "__NewThankYouPage__",
    "__Client__",
  ]) {
    if (location.search.indexOf(urlFlag) !== -1) {
      localStorage.feature_Client = true;
      break;
    }
  }

  for (const legacyStorageFlag of [
    "feature_NewProductCard",
    "feature_NewCart",
    "feature_NewCheckout",
    "feature_NewThankYouPage",
  ]) {
    if (localStorage.getItem(legacyStorageFlag) === "true") {
      localStorage.feature_Client = true;
      localStorage.removeItem(legacyStorageFlag);
    }
  }

  return (
    localStorage.feature_Client === "true" || Skubacz.configuration.new_client
  );
}

client.executeImmediately();

window.Skubacz.Store = Store;

// Exports for tests.
// Should probably be moved to their own entry point.
window.Skubacz.loadTestExports = () =>
  import(
    /* webpackChunkName: "Creator" */ "../../output/Restaumatic.Site.Components.Creator/index.js"
  )
    .then((MenuV2Creator) => {
      window.Skubacz.MenuV2Creator = MenuV2Creator;
    })
    .then(() =>
      import(
        /* webpackChunkName: "Creator" */ "../../output/Restaumatic.Menu.V2.JS/index.js"
      )
    )
    .then((Encoding) => {
      window.Skubacz.Encoding = Encoding;
    });

function init() {
  testLocalStorage();
  client.initCookieConsent();
  client.initApp();
  initRating();
  client.initMenuPage();
  client.initCheckout();
  client.initOrderInfo();
  client.initReservation();
  client.initConsents();
  client.initSelectRestaurant();
  client.initDeliveryForm();
  initCartFabAttention();
}

document.addEventListener("DOMContentLoaded", () => {
  if (window.__pageInitHook__) {
    window.__pageInitHook__().then(init);
  } else {
    init();
  }
});
